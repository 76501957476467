import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _484dd800 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _7b3d9503 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _542d3fed = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _aedee694 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _b97e2a8a = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _acef501e = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _35b7e29b = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2d83fd48 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _3293b0b0 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _60c74d5c = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _2752aa28 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _87b6d250 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _0815c3e8 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _1696dc3a = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _81362bb8 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _01eebe09 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _403e964c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _484dd800,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _7b3d9503,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _542d3fed,
    name: "callback"
  }, {
    path: "/downloads",
    component: _aedee694,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _b97e2a8a,
    name: "guests-details"
  }, {
    path: "/login",
    component: _acef501e,
    name: "login"
  }, {
    path: "/maintenance",
    component: _35b7e29b,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _2d83fd48,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _3293b0b0,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _60c74d5c,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _2752aa28,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _87b6d250,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _0815c3e8,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _1696dc3a,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _81362bb8,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _01eebe09,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _403e964c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
